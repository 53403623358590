import React from 'react';
import bgImage from "assets/siteIntro/blog/images/bgImage.png";
import { LazyImage } from 'components/image';

function Index() {
    return (
        <div className="relative w-full h-fit">
            {/* Background Image */}
            <LazyImage
                src={bgImage}
                className="w-full max-h-[calc(100vh-80px)"
            />

            {/* Centered Text */}
            <div className="absolute inset-0 flex items-center justify-center">
                <h1 className="text-white text-9xl">Blogs</h1>
            </div>
        </div>
    );
}

export default Index;
